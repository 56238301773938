import { render, staticRenderFns } from "./ViewPayment.vue?vue&type=template&id=342b80d8&scoped=true&"
import script from "./ViewPayment.vue?vue&type=script&lang=js&"
export * from "./ViewPayment.vue?vue&type=script&lang=js&"
import style0 from "./ViewPayment.vue?vue&type=style&index=0&id=342b80d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342b80d8",
  null
  
)

export default component.exports